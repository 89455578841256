<template>
  <div>
    <Backpage title="config.retweet">
      <template #header>
        <el-button
          type="primary"
          @click="add"
        >
          {{ $t("common.add") }}
        </el-button>
        <el-button
          type="primary"
          :disabled="chooseRows.length===0"
          @click="sendRtsp"
        >
          {{ $t("retweet.sendSelected") }}
        </el-button>
        <el-button
          type="danger"
          :disabled="chooseRows.length===0"
          @click="deleteSelected"
        >
          {{ $t("common.deleteSelect") }}
        </el-button>
      </template>
      <el-table
        v-loading="tableLoading"
        border
        :data="rtmpList"
        :element-loading-text="$t('common.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        @select="handleSelect"
        @select-all="handleSelectAll"
      >
        <el-table-column
          type="selection"
          width="40"
        />
        <el-table-column
          :label="$t('retweet.addr')"
          prop="addr"
        />
        <el-table-column
          :label="$t('common.port')"
          prop="port"
        />
        <el-table-column
          :label="$t('retweet.turn_push_type')"
          prop="turn_push_type"
        >
          <template slot-scope="scope">
            {{ transferType(scope.row.turn_push_type) }}
          </template>
        </el-table-column>
        <el-table-column
          label="uri"
          prop="uri"
        />
        <el-table-column
          :label="$t('common.operate')"
          width="300"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.row)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="handleSend(scope.row.id)"
            >
              {{ $t("config.send") }}
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row.id)"
            >
              {{ $t("common.delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </Backpage>
    <!-- 添加配置 -->
    <el-dialog
      :title="$t('retweet.rtmpDialogTitle')"
      :visible.sync="rtmpDiaolg"
      width="700px"
    >
      <el-form
        ref="rtmpForm"
        :model="rtmpMsg"
        inline
        label-width="120px"
        :rules="rtmpMsgRules"
      >
        <el-form-item
          :label="$t('retweet.addr')"
          prop="addr"
        >
          <el-input v-model="rtmpMsg.addr" />
        </el-form-item>
        <el-form-item
          :label="$t('common.port')"
          prop="port"
        >
          <el-input v-model.number="rtmpMsg.port" />
        </el-form-item>
        <el-form-item
          label="uri"
          prop="uri"
        >
          <el-input v-model="rtmpMsg.uri" />
        </el-form-item>
        <el-form-item
          :label="$t('retweet.turn_push_type')"
          prop="turn_push_type"
        >
          <el-select
            v-model="rtmpMsg.turn_push_type"
            :popper-append-to-body="false"
            style="width:177px"
          >
            <el-option
              v-for="radio in retweetTypeOption"
              :key="radio.option_value"
              :value="radio.option_value"
              :label="radio.option_label"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="rtmpDiaolg = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="confirmAddRtmp"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 发布 -->
    <el-dialog
      :title="$t('editServer.chooseServer')"
      :visible.sync="sendDialog"
      width="500px"
    >
      <el-select
        v-model="chooseServer"
        :popper-append-to-body="false"
        clearable
        filterable
        multiple
      >
        <el-option
          v-for="item in serverList"
          :key="item.id"
          :value="item.id"
          :label="item.server_ip"
        />
      </el-select>
      <span slot="footer">
        <el-button @click="sendDialog = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="confirmSend"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 发布结果展示 -->
    <el-dialog
      :title="$t('editServer.groupSenddetail')"
      :visible.sync="sendResultDialog"
      width="500px"
    >
      <el-table
        :data="sendResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('retweet.serverIP')"
        />
        <el-table-column
          prop="msg"
          :label="$t('editServer.sendDetail')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.sendResult')"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            > {{ releaseFilter(scope.row.success) }} </span>
            <span
              v-else
              style="color: #F56C6C"
            > {{ releaseFilter(scope.row.success) }} </span>
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="sendResultDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 删除结果展示 -->
    <el-dialog
      :title="$t('editServer.deleteDetail')"
      :visible.sync="retweetDeleteDialog"
    >
      <el-table
        :data="deleteResult"
        border
      >
        <el-table-column
          prop="server_ip"
          :label="$t('editServer.serverIp')"
        />
        <el-table-column
          prop="msg"
          :label="$t('common.remark')"
        />
        <el-table-column
          prop="success"
          :label="$t('editServer.deleteDetail')"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.success"
              style="color:#67C23A"
            > {{ releaseFilter(scope.row.success) }} </span>
            <span
              v-else
              style="color: #F56C6C"
            > {{ releaseFilter(scope.row.success) }} </span>
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="retweetDeleteDialog = false"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  group_rtmp_list,
  add_rtmp,
  update_rtmp,
  delete_rtmp,
  get_server_list_select,
  send_rtmp
} from '@/api/config'
import { get_sename_list } from '@/api/api.js'
import Backpage from '@/components/Backpage'
export default {
  components: {
    Backpage
  },
  data () {
    return {
      retweetDeleteDialog: false,
      deleteResult: [],
      rtmpList: [],
      serverList: [],
      chooseServer: [],
      chooseRows: [],
      rtmpDiaolg: false,
      sendDialog: false,
      sendResultDialog: false,
      sendResult: [],
      tableLoading: false,
      isAdd: false,
      rtmpMsg: {
        group_name: this.$route.params.groupname,
        addr: '',
        port: null,
        uri: '',
        turn_push_type: ''
      },
      rtmpMsgRules: {
        addr: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        port: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { type: 'number', message: this.$t('form.number'), trigger: 'blur' }
        ],
        uri: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        turn_push_type: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      retweetTypeOption: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    // 获取转推类型列表
    async getSelectOptions () {
      const res = await get_sename_list({ select_name: 'turn_push_type' })
      this.retweetTypeOption = res.data.data
    },
    // 过滤发布结果
    releaseFilter (value) {
      if (value) {
        return this.$t('common.succ')
      } else {
        return this.$t('common.fail')
      }
    },
    // 转换转推类型
    transferType (type) {
      if (type === undefined) return
      const typeObj = this.retweetTypeOption.find((item) => {
        return item.option_value === type
      })
      return typeObj.option_label
    },
    // 添加或编辑转推配置
    add () {
      this.isAdd = true
      this.rtmpDiaolg = true
      this.rtmpMsg = {
        group_name: this.$route.params.groupname,
        addr: '',
        port: null,
        uri: '',
        turn_push_type: ''
      }
    },
    // 下发
    handleSend (id) {
      this.sendDialog = true
      this.rtmpids = [id]
    },
    sendRtsp () {
      this.sendDialog = true
    },
    // 确认下发
    async confirmSend () {
      const loading = this.$globalLoading(this.$t('editServer.sending'))
      const res = await send_rtmp({
        turn_push_ids: this.chooseRows.length === 0 ? this.rtmpids : this.chooseRows,
        server_ids: this.chooseServer
      })
      if (res.data.code === 0) {
        // this.$message.success(this.$t("config.sendSuccess"))
        this.sendResult = res.data.data
        this.sendResultDialog = true
        this.sendDialog = false
      }
      loading.close()
    },
    // 全选
    handleSelectAll (selection) {
      this.chooseRows = selection.map((item) => {
        return item.id
      })
    },
    async getServerListSelect () {
      const res = await get_server_list_select()
      if (res.data.code === 0) {
        this.serverList = res.data.data
      }
    },
    // 选中
    handleSelect (selection) {
      this.chooseRows = selection.map((item) => {
        return item.id
      })
    },
    // 删除选中
    async deleteSelected () {
      await this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      })
      const loading = this.$globalLoading()
      const res = await delete_rtmp({ ids: this.chooseRows })
      if (res.data.code === 0) {
        this.retweetDeleteDialog = true
        this.deleteResult = res.data.data
        //  this.$message.success(this.$t("common.deleteSuccess"))
        this.chooseRows = []
        this.getrtmpList()
      }
      loading.close()
    },
    // 确认添加
    confirmAddRtmp () {
      this.$refs.rtmpForm.validate(async (valid) => {
        if (valid) {
          let loading
          if (this.isAdd) {
            loading = this.$globalLoading()
            const res = await add_rtmp(this.rtmpMsg)
            if (res.data.code === 0) {
              this.$message.success(this.$t('common.addSuccess'))
              this.getrtmpList()
              this.rtmpDiaolg = false
            }
          } else {
            const { id, addr, uri, port, turn_push_type } = this.rtmpMsg
            loading = this.$globalLoading()
            const res = await update_rtmp({ id, addr, uri, port, turn_push_type })
            if (res.data.code === 0) {
              this.$message.success(this.$t('common.changeSuccess'))
              this.getrtmpList()
              this.rtmpDiaolg = false
            }
          }
          loading.close()
        }
      })
    },
    // 编辑
    handleEdit (row) {
      this.isAdd = false
      this.rtmpDiaolg = true
      this.rtmpMsg = row
    },
    // 删除
    async handleDelete (id) {
      await this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      })
      const loading = this.$globalLoading()
      const res = await delete_rtmp({ ids: [id] })
      if (res.data.code === 0) {
        this.retweetDeleteDialog = true
        //  this.$message.success(this.$t("common.deleteSuccess"))
        this.deleteResult = res.data.data
        this.getrtmpList()
      }
      loading.close()
    },
    // 获取转推列表
    async getrtmpList () {
      this.tableLoading = true
      const res = await group_rtmp_list({ group_name: this.$route.params.groupname })
      if (res.data.code === 0) {
        this.rtmpList = res.data.data
      }
      this.tableLoading = false
    },
    init () {
      this.getSelectOptions()
      this.getServerListSelect()
      this.getrtmpList()
    }
  }
}
</script>

<style lang='scss' scoped></style>
